<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="Wellbeing" :seeMore="true" />
      <gauge
        v-if="
          parseInt(interaction.surveyTypeId) ===
            parseInt(getConstant('SURVEY_KYMATIO_CLIMATE_PLUS_BLUE'))
        "
        :title="$t('generic.modules.wellbeing.title')"
        :value="wellbeing * 10 || 0"
        class="mt-8"
        style="border-radius: 30px;"
        :formatter="
          val => {
            return wellbeing
              ? $n((Math.round(wellbeing * 10) / 10).toFixed(1)) + '/10'
              : '?/10';
          }
        "
      />
    </div>
    <div class="col-xl-9">
      <Tabs :tabs="tabs" :object="interaction" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";
import Gauge from "@/components/profile/Gauge.component";

import { getUserActions, getUserProfile } from "@/api/user/traits.api";
import { extractValueFromProfile } from "@/utils/helpers";

import _ from "lodash";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "InformationSecurity",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      object: {},
      wellbeing: 0
    };
  },
  components: {
    Card,
    Tabs,
    Gauge
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc2",
        image: this.interaction.image || "home-heart.svg",
        title: this.$t("generic.modules.wellbeing.title"),
        description: this.$t("generic.modules.wellbeing.description")
      };
    }
  },
  methods: {},
  mounted() {
    var profile = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_WORKING")],
      dimensions: [this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL")],
      surveyEntities: [this.interaction.surveyEntityId]
    };
    var insiderActionParams = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      surveyEntities: [this.interaction.surveyEntityId]
    };
    axios
      .all([getUserProfile(profile), getUserActions(insiderActionParams)])
      .then(
        axios.spread((profile, resp) => {
          var result = resp.data.records;
          _.forEach(result, e => {
            this.object.recommendation = {
              text: e.executeAction.description,
              assets: e.executeAction.assets
            };
            this.tabs = resp.data.records;
          });
          this.wellbeing =
            extractValueFromProfile(
              profile.data.records,
              "DIMENSION_GROUP_WORKING",
              this.interaction.surveyTypeId,
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_CLIMATE_LEVEL",
              this.interaction.surveyEntityId
            ) || 0;
        })
      );
  }
};
</script>
