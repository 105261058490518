var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        parseInt(_vm.interaction.typeId) ===
          parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_CYBERSECURITY'))
    )?_c('Cyberawareness',{attrs:{"interaction":_vm.interaction}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        (parseInt(_vm.interaction.surveyTypeId) ===
          parseInt(_vm.getConstant('SURVEY_KYMATIO_IMPACT')) ||
          parseInt(_vm.interaction.surveyTypeId) ===
            parseInt(_vm.getConstant('SURVEY_KYMATIO_IMPACT_REFRESH')))
    )?_c('Impact',{attrs:{"interaction":_vm.interaction,"module":'impact'}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        (parseInt(_vm.interaction.surveyTypeId) ===
          parseInt(_vm.getConstant('SURVEY_KYMATIO_ARCHETYPE')) ||
          parseInt(_vm.interaction.surveyTypeId) ===
            parseInt(_vm.getConstant('SURVEY_KYMATIO_ARCHETYPE_REFRESH')) ||
          parseInt(_vm.interaction.surveyTypeId) ===
            parseInt(_vm.getConstant('SURVEY_KYMATIO_ARCHETYPE_NLP')))
    )?_c('SocialEngineering',{attrs:{"interaction":_vm.interaction}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        (parseInt(_vm.interaction.typeId) ===
          parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_DIRECT')) ||
          parseInt(_vm.interaction.typeId) ===
            parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_GRI_PLUS')))
    )?_c('InformationSecurity',{attrs:{"interaction":_vm.interaction}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        parseInt(_vm.interaction.typeId) ===
          parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_CLIMA_PLUS'))
    )?_c('Wellbeing',{attrs:{"interaction":_vm.interaction}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        parseInt(_vm.interaction.typeId) ===
          parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_BURNOUT'))
    )?_c('Burnout',{attrs:{"interaction":_vm.interaction}}):(
      _vm.isInteractionsLoaded &&
        _vm.interaction &&
        parseInt(_vm.interaction.typeId) ===
          parseInt(_vm.getConstant('SURVEYTYPE_KYMATIO_CSAAS'))
    )?_c('MyOrganization',{attrs:{"interaction":_vm.interaction}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }