<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="MyOrganization" :seeMore="true" />
      <Gauge
        :title="$t('training.detail.puntuación')"
        :value="parseFloat(result) * 10"
        :formatter="
          val => {
            return this.$n(Math.round(this.result * 10) / 10) + '/10';
          }
        "
        class="mt-8"
        style="border-radius: 30px;"
      />
    </div>
    <div class="col-xl-9">
      <Tabs :tabs="tabs" :object="interaction" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import Gauge from "@/components/profile/Gauge.component";
import Tabs from "@/components/training/Tabs.component";

import { getUserActions, getUserProfile } from "@/api/user/traits.api";
import { extractValueFromProfile } from "@/utils/helpers";

import { mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  name: "MyOrganization",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      object: {},
      result: 0
    };
  },
  components: {
    Card,
    Gauge,
    Tabs
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", ["interactions"]),
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc3",
        image: this.interaction.image || "companies.svg",
        title: this.$t("generic.modules.organization.title"),
        description: this.$t("generic.modules.organization.description")
      };
    },
    wellbeing() {
      return 0;
    }
  },
  methods: {},
  mounted() {
    let profile = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CUSTOM")],
      dimensions: [this.getConstant("DIMENSION_ELEMENT_CUSTOM_RESULT")],
      surveyEntities: [this.interaction.surveyEntityId]
    };
    let params = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CUSTOM")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_CUSTOM_PILLS"),
        this.getConstant("ACTIONS_TYPE_CUSTOM_RESULT")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      maxValue: "NO",
      surveyEntities: [this.interaction.surveyEntityId]
    };
    axios.all([getUserActions(params), getUserProfile(profile)]).then(
      axios.spread((actionsResp, profileResp) => {
        this.result =
          extractValueFromProfile(
            profileResp.data.records,
            "DIMENSION_GROUP_CUSTOM",
            this.interaction.surveyTypeId,
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_CUSTOM_RESULT",
            this.interaction.surveyEntityId
          ) || 0;
        this.tabs = _.orderBy(actionsResp.data.records, "dimensionId", "asc");
      })
    );
  }
};
</script>
