<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="SocialEngineering" :seeMore="true" />
    </div>
    <div class="col-xl-9">
      <div class="row">
        <div class="col-12" v-if="hasModule(['socialengineering'])">
          <Tabs :tabs="tabs" :object="interaction" />
        </div>
        <div class="col-12" v-if="hasModule(['archetype']) && archLoaded">
          <profile-tabs
            :archetypeSvg="archetypeSvg"
            :archetypeId="archetypeId"
            :tabs="archetypeTabs"
            :title="archTitle"
            :object="archTabs"
            :info="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import ProfileTabs from "@/components/profile/Tabs.component";
import Tabs from "@/components/training/Tabs.component";

import { getUserActions } from "@/api/user/traits.api";

import { mapGetters } from "vuex";
import _ from "lodash";
import axios from "axios";

export default {
  name: "SocialEngineering",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      archTabs: {},
      object: {},
      wellbeing: 0,
      archTitle: "",
      archetypeSvg: false,
      archetypeId: false,
      archLoaded: false
    };
  },
  components: {
    Card,
    Tabs,
    ProfileTabs
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("Auth", ["hasModule"]),
    archetypeTabs() {
      return [
        {
          key: "description",
          title: this.$t("profile.tabs.description"),
          icon: "flaticon2-chat-1"
        },
        {
          key: "motivation",
          title: this.$t("profile.tabs.motivation"),
          icon: "flaticon2-drop"
        },
        {
          key: "work",
          title: this.$t("profile.tabs.work"),
          icon: "flaticon2-shield"
        },
        {
          key: "strengths",
          title: this.$t("profile.tabs.strengths"),
          icon: "fa fa-dumbbell"
        },
        {
          key: "challenges",
          title: this.$t("profile.tabs.challenges"),
          icon: "fa fa-mountain"
        }
      ];
    },
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc4",
        image: this.interaction.image || "fingerprint.svg",
        title: this.$t("generic.modules.social.title"),
        description: this.$t("generic.modules.social.description")
      };
    }
  },
  methods: {},
  mounted() {
    var params = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM"),
        this.getConstant("ACTIONS_TYPE_SE_RECOMENDATION"),
        this.getConstant("ACTIONS_TYPE_SE_GENERAL")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      maxValue: "NO",
      surveyEntities: [this.interaction.surveyEntityId]
    };

    var actionParams = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_ARCHETYPES")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_MOTIVATION"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_WORK"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_STRENGTHS"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_CHALLENGES"),
        this.getConstant("ACTIONS_TYPE_ARCHETYPES_TELEWORKING")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      surveyEntities: [this.interaction.surveyEntityId]
    };
    axios.all([getUserActions(params), getUserActions(actionParams)]).then(
      axios.spread((resp, archResp) => {
        // archetypeSvg
        // archetypeId
        this.archTitle = _.first(archResp.data.records)?.executeAction?.title;

        let dimensions = {
          description: this.getConstant("ACTIONS_TYPE_ARCHETYPES_DESCRIPTION"),
          motivation: this.getConstant("ACTIONS_TYPE_ARCHETYPES_MOTIVATION"),
          work: this.getConstant("ACTIONS_TYPE_ARCHETYPES_WORK"),
          strengths: this.getConstant("ACTIONS_TYPE_ARCHETYPES_STRENGTHS"),
          challenges: this.getConstant("ACTIONS_TYPE_ARCHETYPES_CHALLENGES")
        };

        _.forEach(archResp.data.records, o => {
          if (parseInt(o.actionTypeId) === parseInt(dimensions.description)) {
            this.archTabs["name"] = { text: o.executeAction.title };
            this.archTabs["description"] = {
              text: o.executeAction.description
            };
          } else if (
            parseInt(o.actionTypeId) === parseInt(dimensions.motivation)
          ) {
            this.archTabs["motivation"] = {
              text: o.executeAction.description,
              assets: o.executeAction.assets
            };
          } else if (parseInt(o.actionTypeId) === parseInt(dimensions.work)) {
            this.archTabs["work"] = {
              text: o.executeAction.description,
              assets: o.executeAction.assets
            };
          } else if (
            parseInt(o.actionTypeId) === parseInt(dimensions.strengths)
          ) {
            this.archTabs["strengths"] = {
              text: o.executeAction.description,
              assets: o.executeAction.assets
            };
          } else if (
            parseInt(o.actionTypeId) === parseInt(dimensions.challenges)
          ) {
            this.archTabs["challenges"] = {
              text: o.executeAction.description,
              assets: o.executeAction.assets
            };
          }
        });
        this.archLoaded = true;

        var tabs = resp.data.records;
        _.each(tabs, tab => {
          switch (parseInt(tab.actionTypeId)) {
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM")):
              tab.order = 0;
              break;
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_RECOMENDATION")):
              tab.order = 1;
              break;
            case parseInt(this.getConstant("ACTIONS_TYPE_SE_GENERAL")):
              tab.order = 2;
              break;
            default:
              tab.order = 0;
          }
        });
        this.tabs = _.orderBy(tabs, "order");
      })
    );
  }
};
</script>
