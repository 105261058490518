<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="Burnout" :seeMore="true" />
    </div>
    <div class="col-xl-9">
      <Tabs :tabs="tabs" :object="interaction" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";

import { getUserActions } from "@/api/user/traits.api";

import { mapGetters } from "vuex";

export default {
  name: "Burnout",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      wellbeing: 0
    };
  },
  components: {
    Card,
    Tabs
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", ["interactions"]),
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc7",
        image: this.interaction.image || "fire.svg",
        title: this.$t("generic.modules.burnout.title"),
        description: this.$t("generic.modules.burnout.description")
      };
    }
  },
  methods: {},
  mounted() {
    var params = {
      surveyTypes: [this.interaction.surveyTypeId],
      surveyEntities: [this.interaction.surveyEntityId],
      dimensions: [
        this.getConstant("DIMENSION_ELEMENT_BURNOUT_NOTE"),
        this.getConstant("DIMENSION_ELEMENT_BURNOUT_MEDIUM_NOTE")
      ],
      actionTypes: [this.getConstant("ACTIONS_TYPE_RECOMMENDATION")],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      maxValue: "NO",
      showExternal: false
    };
    getUserActions(params).then(resp => {
      this.tabs = resp.data.records;
    });
  }
};
</script>
