<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="Security" :seeMore="true" />
    </div>
    <div class="col-xl-9">
      <Tabs :tabs="tabs" :object="interaction" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";
import { extractValueFromProfile } from "@/utils/helpers";
import { getUserActions, getUserProfile } from "@/api/user/traits.api";
import { mapGetters } from "vuex";

export default {
  name: "InformationSecurity",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      wellbeing: 0,
      showWellbeing: true
    };
  },
  components: {
    Card,
    Tabs
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", ["interactions"]),
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc1",
        image: this.interaction.image || "mail-locked.svg",
        title: this.$t("generic.modules.security.title"),
        description: this.$t("generic.modules.security.description")
      };
    }
  },
  methods: {},
  mounted() {
    if (
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_RED")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_GREEN_REFRESH")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_RED_REFRESH")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_ARCHETYPE")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_ARCHETYPE_NLP")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_ARCHETYPE_REFRESH")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_IMPACT")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_IMPACT_REFRESH")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE")) ||
      parseInt(this.interaction.surveyTypeId) ===
        parseInt(this.getConstant("SURVEY_KYMATIO_INSIDER_BLUE_REFRESH"))
    ) {
      var wellbeingParams = {
        surveyTypes: [this.interaction.surveyTypeId],
        groupDimensions: [this.getConstant("DIMENSION_GROUP_WORKING")],
        dimensions: [this.getConstant("DIMENSION_ELEMENT_CLIMATE_LEVEL")],
        surveyEntities: [this.interaction.surveyEntityId]
      };
      getUserProfile(wellbeingParams).then(resp => {
        this.wellbeing =
          extractValueFromProfile(
            resp.data.records,
            "DIMENSION_GROUP_WORKING",
            this.interaction.surveyTypeId,
            "dimensionValue",
            this.interactions,
            "DIMENSION_ELEMENT_CLIMATE_LEVEL",
            this.interaction.surveyEntityId
          ) || 0;
      });
    } else {
      this.showWellbeing = false;
    }
    var insiderActionParams = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [
        this.getConstant("DIMENSION_GROUP_INSIDER"),
        this.getConstant("DIMENSION_GROUP_GRIPLUS"),
        this.getConstant("DIMENSION_GROUP_RECOMEND_CLIMATE")
      ],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_RECOMMENDATION"),
        this.getConstant("ACTIONS_TYPE_PILLS")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      surveyEntities: [this.interaction.surveyEntityId],
      maxValue: "YES"
    };
    getUserActions(insiderActionParams).then(resp => {
      this.tabs = resp.data.records;
    });
  }
};
</script>
