var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-0 mt-lg-3"},[_c('div',{staticClass:"col-xl-3"},[_c('Card',{attrs:{"card":_vm.card,"titleLink":"Wellbeing","seeMore":true}}),(
        parseInt(_vm.interaction.surveyTypeId) ===
          parseInt(_vm.getConstant('SURVEY_KYMATIO_CLIMATE_PLUS_BLUE'))
      )?_c('gauge',{staticClass:"mt-8",staticStyle:{"border-radius":"30px"},attrs:{"title":_vm.$t('generic.modules.wellbeing.title'),"value":_vm.wellbeing * 10 || 0,"formatter":val => {
          return _vm.wellbeing
            ? _vm.$n((Math.round(_vm.wellbeing * 10) / 10).toFixed(1)) + '/10'
            : '?/10';
        }}}):_vm._e()],1),_c('div',{staticClass:"col-xl-9"},[_c('Tabs',{attrs:{"tabs":_vm.tabs,"object":_vm.interaction}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }