<template>
  <div>
    <Cyberawareness
      v-if="
        isInteractionsLoaded &&
          interaction &&
          parseInt(interaction.typeId) ===
            parseInt(getConstant('SURVEYTYPE_KYMATIO_CYBERSECURITY'))
      "
      :interaction="interaction"
    />
    <Impact
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          (parseInt(interaction.surveyTypeId) ===
            parseInt(getConstant('SURVEY_KYMATIO_IMPACT')) ||
            parseInt(interaction.surveyTypeId) ===
              parseInt(getConstant('SURVEY_KYMATIO_IMPACT_REFRESH')))
      "
      :interaction="interaction"
      :module="'impact'"
    />
    <SocialEngineering
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          (parseInt(interaction.surveyTypeId) ===
            parseInt(getConstant('SURVEY_KYMATIO_ARCHETYPE')) ||
            parseInt(interaction.surveyTypeId) ===
              parseInt(getConstant('SURVEY_KYMATIO_ARCHETYPE_REFRESH')) ||
            parseInt(interaction.surveyTypeId) ===
              parseInt(getConstant('SURVEY_KYMATIO_ARCHETYPE_NLP')))
      "
      :interaction="interaction"
    />
    <InformationSecurity
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          (parseInt(interaction.typeId) ===
            parseInt(getConstant('SURVEYTYPE_KYMATIO_DIRECT')) ||
            parseInt(interaction.typeId) ===
              parseInt(getConstant('SURVEYTYPE_KYMATIO_GRI_PLUS')))
      "
      :interaction="interaction"
    />
    <Wellbeing
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          parseInt(interaction.typeId) ===
            parseInt(getConstant('SURVEYTYPE_KYMATIO_CLIMA_PLUS'))
      "
      :interaction="interaction"
    />
    <Burnout
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          parseInt(interaction.typeId) ===
            parseInt(getConstant('SURVEYTYPE_KYMATIO_BURNOUT'))
      "
      :interaction="interaction"
    />
    <MyOrganization
      v-else-if="
        isInteractionsLoaded &&
          interaction &&
          parseInt(interaction.typeId) ===
            parseInt(getConstant('SURVEYTYPE_KYMATIO_CSAAS'))
      "
      :interaction="interaction"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Cyberawareness from "@/components/training/Cyberawareness.component";
import InformationSecurity from "@/components/training/InformationSecurity.component";
import Wellbeing from "@/components/training/Wellbeing.component";
import Impact from "@/components/training/Impact.component";
import SocialEngineering from "@/components/training/SocialEngineering.component";
import Burnout from "@/components/training/Burnout.component";
import MyOrganization from "@/components/training/MyOrganization.component";

export default {
  name: "TrainingDetail",
  components: {
    Cyberawareness,
    InformationSecurity,
    Wellbeing,
    Impact,
    SocialEngineering,
    Burnout,
    MyOrganization
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    ...mapGetters("Interactions", [
      "isInteractionsLoaded",
      "getBySurveyEntityId"
    ]),
    interaction() {
      return this.getBySurveyEntityId(this.$route.params.id);
    }
  },
  methods: {
    ...mapActions("Interactions", ["loadInteractions"])
  },
  mounted() {
    if (this.isConstantsActive && !this.isInteractionsLoaded) {
      this.loadInteractions();
    }
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadInteractions();
    }
  }
};
</script>
