<template>
  <div class="row mt-0 mt-lg-3">
    <div class="col-xl-3">
      <Card :card="card" titleLink="Awareness" :seeMore="true" />
      <Gauge
        :title="$t('training.detail.puntuación')"
        :value="parseFloat(cyberResult) * 10"
        :formatter="
          val => {
            return this.$n(Math.round(this.cyberResult * 10) / 10) + '/10';
          }
        "
        class="mt-8"
        style="border-radius: 30px;"
      />
    </div>
    <div class="col-xl-9">
      <Tabs :tabs="tabs" :object="interaction" />
    </div>
  </div>
</template>

<script>
import Card from "@/components/profile/Card.component";
import Gauge from "@/components/profile/Gauge.component";
import Tabs from "@/components/training/Tabs.component";

import { getUserActions, getUserProfile } from "@/api/user/traits.api";
import { extractValueFromProfile } from "@/utils/helpers";

import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Cyberawareness",
  props: {
    interaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tabs: [],
      object: {},
      cyberResult: 0
    };
  },
  components: {
    Card,
    Gauge,
    Tabs
  },
  computed: {
    ...mapGetters("Constants", ["getConstant"]),
    ...mapGetters("User", ["interactions"]),
    card() {
      return {
        progress: false,
        color: this.interaction.color || "tc3",
        image: this.interaction.image || "shield-protected.svg",
        title: this.$t("generic.modules.awareness.title"),
        description: this.$t("generic.modules.awareness.description")
      };
    },
    wellbeing() {
      return 0;
    }
  },
  methods: {},
  mounted() {
    var profile = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CIBERSECURITY")],
      dimensions: [this.getConstant("DIMENSION_ELEMENT_CIBER_RESULTS")],
      surveyEntities: [this.interaction.surveyEntityId]
    };
    var cyberActionParams = {
      surveyTypes: [this.interaction.surveyTypeId],
      groupDimensions: [this.getConstant("DIMENSION_GROUP_CIBERSECURITY")],
      actionTypes: [
        this.getConstant("ACTIONS_TYPE_CYBER_PILLS"),
        this.getConstant("ACTIONS_TYPE_CYBER_RESULT")
      ],
      entityType: this.getConstant("ENTITY_TYPE_USER"),
      maxValue: "NO",
      surveyEntities: [this.interaction.surveyEntityId]
    };
    axios
      .all([getUserActions(cyberActionParams), getUserProfile(profile)])
      .then(
        axios.spread((actionsResp, profileResp) => {
          this.cyberResult =
            extractValueFromProfile(
              profileResp.data.records,
              "DIMENSION_GROUP_CIBERSECURITY",
              this.interaction.surveyTypeId,
              "dimensionValue",
              this.interactions,
              "DIMENSION_ELEMENT_CIBER_RESULTS",
              this.interaction.surveyEntityId
            ) || 0;
          this.tabs = actionsResp.data.records;
        })
      );
  }
};
</script>
